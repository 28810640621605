import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
}
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}
  
const T04Services = ({ data, desc }) => {
    const { t } = useTranslation();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <section className="services-section">
                <div className="row mx-0 w-100 mb-5">
                    <div className="col-12 col-lg-5 d-flex flex-column">
                        <span className="section-prefix tag" data-aos="fade-left" data-aos-duration="1500">{t("OurServices")}</span>
                        <h2 className='sec-title text-start' data-aos="fade-up" data-aos-duration="1500">{t("ServicesWeProvide")}</h2>
                    </div>
                    <div className={`col-12 col-lg-7 ${desc ? 'border-lg-start' : ''}`} data-aos="fade-right" data-aos-duration="1500">
                        <p className="sec-desc text-start">{desc}</p>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12">
                        <div className="service-tab-wrap">
                                
                            <Tabs TabIndicatorProps={{ style: { display: "none" } }}  className="nav nav-tabs" value={value} onChange={handleChange}>
                                {data.map((item, index) => (
                                    <Tab
                                        key={index}
                                        className={`nav-item`}
                                        label={<button className={`nav-link ${value === index ? 'active' : ''}`}>{item.title}</button>}
                                        {...a11yProps(index)}
                                    />
                                ))}
                            </Tabs>
                            {data.map((item, index) => (
                                <CustomTabPanel className="tab-content" value={value} index={index} key={index}>
                                    <div className="tab-pane">
                                        <div className="service-item shine-animate-item">
                                            <div className="service-item-thumb shine-animate">
                                                <img src={item.icon} alt="Apexa" />
                                            </div>
                                            <div className="service-item-content">
                                                <h2 className="title">{item.title}</h2>
                                                <p>{item.description_small}</p>
                                            </div>
                                        </div>
                                    </div>
                                </CustomTabPanel>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default T04Services;
