import React, { useState, useEffect } from "react";
import CompanyCRVerficationForm from "./company-cr-verfication-form";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../helpers/context";
import { preventString } from "../../../../helpers";
import { VerifyCompanyCR } from "../../../../api/userApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const CompanyValidation = (props) => {
  const [errors, setErrors] = useState({});
  const { token } = useAuth();
  const [disable, setDisable] = useState(false);
  const history = useNavigate();
  const { t, i18n } = useTranslation();
  const [_CRResponse, set_CRResponse] = useState({ managers: { response: {}, resCode: 1, resStr: "" } });
  const [user, setUser] = useState({
    // id: null,
    expiryInHijri: "",
    commercialRecordNumber: "",
    userIdHashed: token,
    language: i18n.language === 'ar' ? '0' : '1',
  });
  const [date, setDate] = useState({
    day: "",
    month: "",
    year: "",
  });

  function handleChange({ target }) {
    let value = target.value;
    if (target.name === "commercialRecordNumber")
      value = preventString(value);
    setUser({
      ...user,
      [target.name]: value,
    });
  }

  function handleDateChange({ target }) {
    setDate({
      ...date,
      [target.name]: target.value,
    });
  }

  function formIsValid() {
    const _errors = {};

    if (!user.commercialRecordNumber)
      _errors.commercialRecordNumber = t("EnterTheComapnyCommercialRegistrationNumber");
    if (!date.day) _errors.day = t("EnterTheDay");
    if (!date.month) _errors.month = t("EnterTheMonth");
    if (!date.year) _errors.year = t("EnterTheYear");

    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      formIsValid();
    }
  }, // eslint-disable-next-line
    [i18n.language]);
  
  function handleSubmit(event) {
    event.preventDefault();

    if (!formIsValid()) return;
    setDisable(true);

    // if (date.day < 10) date.day = "0" + date.day;
    // if (date.month < 10) date.month = "0" + date.month;
    user.expiryInHijri = date.year
      + "/" + (+date.month < 10 ? `0${date.month}` : date.month)
      + "/" + (+date.day < 10 ? `0${date.day}` : date.day);
    VerifyCompanyCR({
      ...user,
      commercialRecordNumber: user.commercialRecordNumber,
      language: i18n.language === 'ar' ? '0' : '1'
    }).then((managers) => {
      set_CRResponse({
        managers: {
          response: managers.response,
          resCode: managers.resCode,
          resStr: managers.resStr,
        }
      });
      if (managers.resCode !== 0) {
        toast.error(t("NoInformationFound"));
      }
    }).finally(() => setDisable(false));
    // userApi.VerifyCompanyCR(user).then((_userInfo) => {
    //   if (_userInfo.resCode === 0) {
    //     history("/manageridvalidation/" + user.userIdHashed);
    //     if (_userInfo.resStr !== "success") toast.success(_userInfo.resStr);
    //   } else {
    //     toast.error(_userInfo.resStr);
    //     setDisable(false);
    //   }
    // });
  }

  if (_CRResponse.managers.resCode === 0) history("/manageridvalidation", { state: { _CRResponse: _CRResponse } });

  return (
    <CompanyCRVerficationForm
      errors={errors}
      user={user}
      date={date}
      onChange={handleChange}
      onDateChange={handleDateChange}
      onSubmit={handleSubmit}
      disable={disable}
    />
  );
};

export default CompanyValidation;
