import React, { useEffect, useState } from "react";
import Navbar from "../global-components/navbar";
import Footer from "../global-components/footer";
import FooterV2 from "../global-components/footer-v2";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../helpers/context";
import { getUserProile, getWLProfile } from "../../api/userApi";
import AccessDenied from "../../scenes/shared/access-denied";
import moment from "moment";
import { wlPlansAvailabilty } from "../../constants";
import { useMemo } from "react";
import Forbidden from "../../scenes/shared/403";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import LoadingTemplate from "../global-components/loading-template";
import AmakknNavbar from "../global-components/amakkn-navbar";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const AppLayout = ({
  regaNavMode,
  children,
  withFooter,
  needAuth,
  withoutNav,
  pageTitle = t("Home"),
  unsetOverflow,
}) => {
  const history = useNavigate();
  const {
    token,
    vendor,
    planEndDate,
    loadingTemplate,
    setAccountType,
    AccountType,
    UserType,
    setUserType,
    setToken,
    setUserId,
    setTemplateId,
    templateId,
  } = useAuth();
  const isAdmin = window.location.href.includes("admin");
  const [siteName, setSiteName] = useState("");
  const [accessDenied, setAccessDenied] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState("0");
  const [planId, setPlanId] = useState("0");
  const [isDomainVerified, setIsDomainVerified] = useState("1");
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { i18n } = useTranslation();
  
  const blockedPage = useMemo(() => {
    const AgentsBlackList = [
      "my-subscription",
      "minasati",
      "amakkn-pro",
      "/pro/",
      "agents",
      "add-agent",
      "edit-agent",
      // "change-agent-phone",
      "plan-registration",
    ];
    return (
      AgentsBlackList.filter((item) => location.pathname.includes(item))
        ?.length > 0
    );
  }, [location]);

  useEffect(() => {
    if (vendor !== "amakkn" && planEndDate !== undefined) {
      if (moment(planEndDate).diff(moment(), "day") < 0) {
        setAccessDenied(true);
      }
    }
  }, [vendor, planEndDate]);

  useEffect(
    () => {
      if (
        needAuth &&
        !token &&
        !window.location.pathname.includes("add-property/")
      )
        history(`/401?returnURL=${window.location.pathname}`);
    }, // eslint-disable-next-line
    [token, needAuth]
  );

  useEffect(
    () => {
      if (vendor && vendor !== "amakkn") {
        getWLProfile({ userName: vendor, language: i18n.language === 'ar' ? '0' : '1' }).then((_userInfo) => {
          if (_userInfo.resCode === 0) {
            setSiteName(
              window.location.href.includes("amakkncompany.com")
                ? "مكتب أحمد للعقارات (مثال)"
                : _userInfo.response.wlUser.briefDescription
            );
            setIsUserVerified(_userInfo.response.wlUser.isVerified);
            setPlanId(_userInfo.response.wlUser.planId);
            setAccountType(_userInfo.response.wlUser.accountType + "");
            setTemplateId(_userInfo.response.templateId);
            setIsDomainVerified(_userInfo.response.wlUser.saudiBusinessStatus);
            if (
              _userInfo.response.templateId === 0 &&
              !window.location.pathname.includes("minasati")
            )
              history(`/admin/login/`, { state: { incomplete: true } });
          }
        });
      } else if (token && vendor === "amakkn") {
        getUserProile({
          userId: token,
          language: i18n.language === 'ar' ? '0' : '1',
        }).then((_userInfo) => {
          if (_userInfo.resCode === 0) {
            if (
              _userInfo.response.userProfile.status === "0" ||
              _userInfo.response.userProfile.status === "-2"
            ) {
              toast.error(t("YouHaveBeenLoggedoutYourAccountIsDeactivated"));
              history("/");
              setToken(undefined);
              setUserId(undefined);
              setUserType(undefined);
              setAccountType(undefined);
              document.cookie.split(";").forEach(function (c) {
                if (!c.includes("AmmaknConsent"))
                  document.cookie = c
                    .replace(/^ +/, "")
                    .replace(
                      /=.*/,
                      "=;expires=" + new Date().toUTCString() + ";path=/"
                    );
              });
              sessionStorage?.clear?.();
              localStorage?.clear?.();
            } else {
              setUserType(_userInfo.response.userProfile.userType);
              setAccountType(_userInfo.response.userProfile.accountType);
            }
          } else if (_userInfo.resCode === 16) {
            toast.error(t("YouHaveBeenLoggedoutYourAccountHasBeenDeleted"));
            history("/");
            setToken(undefined);
            setUserId(undefined);
            setUserType(undefined);
            setAccountType(undefined);
            document.cookie.split(";").forEach(function (c) {
              if (!c.includes("AmmaknConsent"))
                document.cookie = c
                  .replace(/^ +/, "")
                  .replace(
                    /=.*/,
                    "=;expires=" + new Date().toUTCString() + ";path=/"
                  );
            });
            sessionStorage?.clear?.();
            localStorage?.clear?.();
          }
        });
      }
    }, // eslint-disable-next-line
    [vendor, token]
  );
  return (
    <>
      <Helmet>
        <title>
          {vendor
            ? vendor === "amakkn"
              ? `${t("Amakkn")} | ${pageTitle}`
              : siteName
                ? `${siteName} | ${pageTitle}`
                : pageTitle
            : ""}
        </title>
        <meta
          property='og:title'
          content={
            vendor
              ? vendor === "amakkn"
                ? `${t("Amakkn")} | ${pageTitle}`
                : siteName
                  ? `${siteName} | ${pageTitle}`
                  : pageTitle
              : ""
          }
        />
        <meta
          name='twitter:title'
          content={
            vendor
              ? vendor === "amakkn"
                ? `${t("Amakkn")} | ${pageTitle}`
                : siteName
                  ? `${siteName} | ${pageTitle}`
                  : pageTitle
              : ""
          }
        />
      </Helmet>
      {loadingTemplate && vendor !== "amakkn" ? (
        <LoadingTemplate />
      ) : (
        <>
          {!withoutNav &&
            (vendor === "amakkn" ? (
              <AmakknNavbar regaNavMode={regaNavMode} />
            ) : (
              <Navbar regaNavMode={regaNavMode} />
            ))}
          <div
            className={window.location.pathname === "/" ? "page-width" : ""}
            style={{ flex: 1 }}>
            <div
              className={
                isAdmin
                  ? ""
                  : `page-top-wrapper ${searchParams.get("dark") === "true" ? "dark" : ""
                  }`
              }
              style={{ overflow: unsetOverflow ? "unset" : "hidden" }}>
              {(accessDenied || planId === "") &&
                !location.pathname.includes("login") &&
                !location.pathname.includes("forgot-password") &&
                !location.pathname.includes("otp-change-password") &&
                !location.pathname.includes("set-new-password") &&
                !location.pathname.includes("success-reset-password") &&
                !location.pathname.includes("/ticket") &&
                !location.pathname.includes("/verify") &&
                !location.pathname.includes("/manageridvalidation") ? (
                <AccessDenied
                  btnLink={
                    wlPlansAvailabilty[AccountType].includes(planId) ||
                      planId === ""
                      ? "/admin/login"
                      : "/verify"
                  }
                  btnText={
                    wlPlansAvailabilty[AccountType].includes(planId) ||
                      planId === "" ? t("GoToAdministration") : t("AccountVerification")
                  }
                  desc={
                    wlPlansAvailabilty[AccountType].includes(planId) ||
                      planId === ""
                      ? planId === "" ? t("YourCurrentPlanDoesNotSupportTheAmakknProService") : t("YourSubscriptionHasExpired")
                      : isUserVerified !== "3" ? t("PleaseVerifyYourAccountToAccessThisPage") : ""
                  }
                // "خطتّك الحاليّة موجهّة لحسابات الأفراد, لذا يجب عليك الإشتراك بإحدى الخطط الموجهّة للشركات"}
                />
              ) : isDomainVerified === "-1" &&
                !window.location.href.includes("amakkn.com") ? (
                <AccessDenied
                  btnLink={"/admin/domain"}
                  btnText={t("CheckOfDomainVerification")}
                  desc={t("CheckOfDomainVerification1")}
                />
              ) : UserType === "5" && blockedPage ? (
                <Forbidden
                  withTitle
                  btnLink={"/"}
                  btnText={t("BackToHomePage")}
                  desc={t("PageNotFound")}
                />
              ) : (
                children
              )}
            </div>

            {withFooter && vendor ? (
              vendor === "amakkn" ? (
                <Footer />
              ) : (
                <FooterV2 templateId={templateId} />
              )
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default AppLayout;
