import React, { useState, useEffect } from "react";
import Navbar from "../../global-components/navbar";
import { crmGetProperties } from "../../../api/userApi";
import PropertyBox from "../property-box";
import { useAuth } from "../../../helpers/context";

const CRMProperties = () => {
  const { token } = useAuth();
  const [user] = useState({
    userId: token,
    language: "0",
    page: "1",
    pageSize: "100",
  });
  const [Properties, setProperties] = useState([]);
  useEffect(() => {
    if (token)
      crmGetProperties(user).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          setProperties(_userInfo.response.propertyArray);
        }
      });
  }, [token, user]);
  return (
    <div>
      <Navbar />
      <div className='about-area pd-top-100 pd-bottom-90'>
        <div className='col-sm-12 col-lg-8'>
          <div className='container mt-4'>
            <div className='row justify-content-center pd-top-30 '>
              {Properties.map((item, i) => (
                <div
                  data-aos='flip-left'
                  className='col-xl-4 col-lg-6 col-md-6 col-sm-6'
                  style={{ cursor: "pointer" }}>
                  <PropertyBox
                    key={i}
                    propertey={item}
                    path='/crm/properties/'
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CRMProperties;
